@import '../../../styles/colors';
@import '../../../styles/shared';

$project-transition-dur: 0.35s;

$portfolio-project-background-darkened: darken($portfolio-project-background, 2);

$portfolio-shadow: -0.06em 0.05em 0 0.05em $portfolio-project-background-darkened;
$portfolio-shadow-upper: -0.17em 0.12em 0 0.1em $portfolio-project-background-darkened;
$portfolio-shadow-solid: -0.3em 0.2em 0 0.1em $portfolio-project-background-darkened;

$portfolio-shadow-inside-solid: -0.3em 0.2em 0 0.1em  darken($portfolio-project-background, 2.5);

.project {
  width: 20em;

  background-color: $portfolio-project-background;
  border-radius: 1em;
  padding: 1em;

  display: flex;
  flex-direction: column;
  row-gap: 1em;

  border-bottom: 0.1px solid rgba($global-background, 0.6);
  border-left: 0.1px solid rgba($global-background, 0.6);

  transition-property: box-shadow, transform;
  transition-duration: $project-transition-dur;

  box-shadow: $portfolio-shadow, $portfolio-shadow-upper, $portfolio-shadow-solid;
  // box-shadow: 0 0 100px darken(#cd8839, 40);

  position: relative; // for 'project__kind'

  ::selection {
    text-shadow: 0 0 6px #515656, 0 0 20px #5156566b !important;
    background-color: #fff !important;
  }

  @media screen and (hover: hover) {
    &:active {
      $hover-shadow: 0 0 0.6em $link-color;
      box-shadow: $hover-shadow;

      transform: translateY(0.3em + 0.05em) translateX(-0.5em);

      .project__container {
        box-shadow: $hover-shadow inset;
      }
    }
  }

  &__image {
    &-scroller {
      border-radius: 0.6em;
      transition-duration: $project-transition-dur;
      transition-property: transform, filter;

      @media screen and (hover: hover) {
        filter: grayscale(0.7) brightness(0.7) saturate(4);
        &:hover {
          transition-delay: 0.1s;
          transform: scale(1.33) translateY(-12%);
          filter: none;
        }
      }
    }

    &-container {
      max-height: 200px;

      overflow-x: hidden;
      overflow-y: auto;

      border-radius: inherit;
    }

    width: 100%;
    max-width: 100%; // max-width and min-width are not needed if width is specified, though I'm living it here just in case.
    user-select: none;
    border-radius: inherit;

    background-color: #000; // visible while image loads
    color: #000; // visible if the image fails to load (alt text). Combined with the same background, makes alt text invisible.

    box-shadow: 0 0 45px #000;
  }

  &__link {
    @include link-shared;

    color: $link-color;

    display: flex;
    flex-direction: column;
    row-gap: 1em;

    @media screen and (hover: hover) {
      &:hover {
        text-decoration-line: underline;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    column-gap: 1em;

    background-color: $global-background;
    border-radius: 1em;
    padding: 0.3em 0.8em;

    transition-property: box-shadow;
    transition-duration: $project-transition-dur;

    box-shadow: $portfolio-shadow-inside-solid inset;

    box-shadow: 0 0 50px darken($link-color, 40);
  }

  &__title {
    margin: 0;
  }

  &__year {
    margin: 0;
  }

  &__subtitle {
    text-align: center;
    margin: 0;
    color: #cd8839;
    text-shadow: 0 0 30px #f19226;
  }

  &__text {
    margin: 0;
    color: #d9e0e3;

    text-align: justify;

    margin: auto 0;
  }

  &__badge {
    &s {
      display: flex;
      justify-content: space-between;
    }

    max-width: 50%;
    max-height: max-content;

    &-link {
      display: flex;
      appearance: none;
      cursor: pointer;
      max-width: 50%;
    }
    &-link > & {
      max-width: unset;
    }
  }

  &__kind {
    position: absolute;
    bottom: (-20px / 4);
    right: (-20px / 4);
    fill: #cd8839;
  }
}
