@import '../../../styles/shared';

.header__language {
  display: flex;
  justify-content: center;

  margin: 0.1em;

  position: relative;

  &::before {
    pointer-events: none;

    content: '';
    position: absolute;
    left: 0;
    top: 5%;

    height: 90%;
    width: 50%;

    border-radius: 0.6em;

    background-color: #ddd;

    transition-duration: 0.4s;
    transition-property: left;
    transition-timing-function: $button-timing-function;

    mix-blend-mode: difference;
  }

  &_moved {
    &::before {
      left: 50%;
    }
  }

  &-button {
    appearance: none;
    cursor: pointer;
    user-select: none;

    margin: 0.1em;
    padding: 0 0.2em;

    background-color: transparent;
    color: #999;
    font-size: 1em;

    border: 0;

    &_selected {
      cursor: default;

      &:active {
        cursor: not-allowed;
      }
    }
  }
}
