@import '../../styles/colors';
@import '../../styles/shared';

.life {
  margin: 0 5vmin;

  &__subtitle {
    color: #cd8839;
    text-shadow: 0 0 30px #f19226;
  }

  &__list {
    padding: 0;
    padding-left: 2px;

    @media screen and (max-width: $breakpoint-mobile) {
      padding-left: 20px;
    }
  }

  &__text {
    color: #ccc;
  }

  &__link {
    &s {
      display: inline-flex;
      column-gap: 10px;
      height: 20px;
    }
    @include link-shared;

    color: $link-color;

    @media screen and (hover: hover) {
      &:hover {
        text-decoration-line: underline;
      }
    }

    &_button {
      // GitHub kbd style
      $color-fg-default: #c9d1d9;
      $color-canvas-subtle: #161b22;
      $color-neutral-muted: rgba(110, 118, 129, 0.4);

      padding: 3px 5px;
      font: 11px ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
      line-height: 10px;
      color: $color-fg-default;
      vertical-align: middle;
      background-color: $color-canvas-subtle;
      border: solid 1px $color-neutral-muted;
      border-bottom-color: $color-neutral-muted;
      border-radius: 6px;
      box-shadow: inset 0 -1px 0 $color-neutral-muted;
    }
  }

  &__badge {
    vertical-align: middle;
  }
}
