@import '../../styles/colors';
@import '../../styles/shared';

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  height: 30px;

  display: flex;
  justify-content: space-between;
  padding-inline: 1em;

  background: linear-gradient(to top, $header-background, 60%, $global-background);
  backdrop-filter: blur(2px);

  box-shadow: 0 0 2em 1em $header-background;

  &__logo {
    @media screen and (max-width: 400px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    font-size: 1.25rem;
    line-height: 1.75rem;
    text-decoration-line: none;
    color: $global-text;
    letter-spacing: -0.08em;
    font-weight: 300;

    border-color: transparent;
    border-style: solid;
    border-width: 1px 0;

    @media screen and (hover: hover) {
      &:hover {
        border-bottom: 1px solid rgba($link-color, 0.4);
      }
    }

    &_active {
      font-weight: 500;
      cursor: default;

      &:active {
        cursor: not-allowed;
      }

      @media screen and (hover: hover) {
        &:hover {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }

  &__navigation {
    padding: 0;
    margin: 0;
    padding-inline: 1em;
    margin-left: 1em;

    display: flex;
    align-items: center;
    column-gap: 1em;

    $dot-size: 3px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: -$dot-size / 2;
      top: auto;
      width: $dot-size;
      height: $dot-size;
      background-color: #525252;
      border-radius: 100%;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__link {
    @include link-shared;

    color: #999;

    display: flex;
    width: 18px;
    > svg {
      height: 100%;
      width: 100%;
    }

    @media screen and (hover: hover) {
      &:hover {
        color: $global-text;
      }
    }

    &_active {
      color: $link-color;
      cursor: default;

      @media screen and (hover: hover) {
        &:hover {
          color: $link-color;
        }
      }

      &:active {
        cursor: not-allowed;
      }
    }
  }
}
