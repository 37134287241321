.pace {
  &-inactive {
    display: unset;
    visibility: hidden;
  }

  & &-progress {
    top: unset;
  }

  &-progress {
    opacity: 0.7;
    transition-duration: 0.1s;

    bottom: 0;
  }
}
