@mixin link-shared {
  text-decoration-line: none;
  -webkit-tap-highlight-color: transparent;
}

$button-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

%default-link-effects {
  user-select: none;
  cursor: pointer;

  @media screen and (hover: hover) {
    &:not(:disabled):active {
      transform: scale(0.8);
      transition-duration: 0.2s;
      transition-timing-function: $button-timing-function;
      transition-delay: 0s;
      transition-timing-function: ease;
    }
  }

  &:disabled {
    cursor: default;

    &:active {
      cursor: not-allowed;
    }
  }
}

// anything wider is for 1280px design
$breakpoint-tablet: 1023px; // for 768px design
$breakpoint-mobile: 694px; // for 320px design
