@use "sass:math";

@import '../../styles/colors';
@import '../../styles/shared';

.portfolio {
  padding: 50px 0;

  display: flex;
  flex-direction: column;
  row-gap: math.div(50px, 2);
  min-height: 100vh;

  color: $global-text;

  margin: 0 3vmin;

  &__category-button {
    &s {
      display: flex;
      flex-wrap: wrap;

      margin-inline: auto;
      justify-content: center;

      gap: 5px;
    }

    appearance: none;
    padding: 0;
    border: 0;
    background: transparent;
    font-family: inherit;
    color: inherit;

    padding-inline: 10px;
    border: 1px solid $portfolio-project-background;
    border-radius: 10px;
    font-size: x-large;
    color: #d9e0e3;

    transition: color 0.3s, transform 0.2s;
    @extend %default-link-effects;

    @media screen and (hover: hover) {
      &:hover {
        text-shadow: 0 0 30px #f19226;
      }
    }

    &_selected {
      color: #cd8839;
      text-shadow: 0 0 30px #f19226;
    }
  }

  &__projects {
    list-style-type: none;
    margin: 0;
    padding: 0;

    margin-bottom: 5em;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    gap: 2em;
  }

  &__quantity {
    margin: 3em auto 0;
    text-align: center;
    color: #444;
  }
}
