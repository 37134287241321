$global-background: #08090a; // should match <meta name="theme-color"> `content` attribute in /public/index.html
$global-text: #f8f8ff;

$link-color: #1be0cf;

$header-background: rgba(
  $color: #000,
  $alpha: 0.6,
);

$portfolio-project-background: #151617;
